@import '~antd/es/style/themes/default.less';

.main {
  width: 328px;
  margin: 0 auto;
  @media screen and (max-width: @screen-sm) {
    width: 95%;
    max-width: 328px;
  }

  :global {
    .@{ant-prefix}-tabs-nav-list {
      margin: auto;
      font-size: 16px;
    }
  }

  .icon {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.2);
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: @primary-color;
    }
  }

  .authInputForm {
    border-radius: 20px;
  }

  .authFormItem {
    margin-bottom: 10px;
  }

  .authCard {
    border-radius: 15px !important;
    border: 1px solid #d9d9d9;
  }

  .other {
    margin-top: 24px;
    line-height: 22px;
    text-align: left;
    .register {
      float: right;
    }
  }

  .prefixIcon {
    color: @primary-color;
    font-size: @font-size-base;
  }
}

@primary-color: #1DA57A;