// @import '~antd/es/style/themes/default.less';
@import '~antd/lib/style/themes/default.less';

.bottomMargin {
  margin-bottom: -1%;
}

.fillRow {
  height: 100%;
}

.inlineTitle {
  display: inline;
}

.pre {
  position: relative;
  margin: 0 0 6px 0;
  padding: 12px 20px;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
  background: @background-color-base;
}

@primary-color: #1DA57A;