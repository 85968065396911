@import '~antd/lib/style/themes/default.less';
.fillRow {
  height: 100%;
}

.assetCard{
  border: 1px solid #d9d9d9;
}

.ant-card-head{
  border-radius: 100px !important;
}

.ant-menu-horizontal{
  border-bottom: 0px !important;
}

.verticalTabs {
  display: flex;
  :global {
    .ant-tabs-nav-list {
      width: 99.39%;
    }

    .ant-tabs-tab {
      border-width: 1px !important;
      border-color: #d9d9d9 !important;
      border-radius: 15px !important;
      flex-grow: 1;
      justify-content: center;
      width: 100%;
      margin-right: 0;
      text-align: center;
    }

    .ant-tabs-tab-active {
      border-width: 3px !important;
      border-color: #1890ff !important;
      color: white !important;
    }

    @media (max-width: 768px) {
      .ant-tabs-nav-list {
        flex-direction: column;
      }
    }

    .ant-tabs-nav-more {
      display: none;
    }
  }

  .tabs {
    :global {
      @media (max-width: 768px) {
        .ant-tabs-nav-list {
          flex-direction: row;
        }
      }
    }
  }
}

.dropdownList {
  min-width: 200px;
}

.dropdownListSearch {
  min-width: 120px;
}

.search {
  :global {
    .ant-input-group-wrapper {
      width:100%;
      height: 100%;

      .ant-input-search-button,
      .ant-input-group-addon,
      .ant-input-group,
      .ant-input-wrapper,
      .ant-input-affix-wrapper {
        height: 100%;
      }
    }
  }
}

.pre {
  margin: 0 0;
  padding: 12px 12px;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
  background: @input-bg;
  border-color: @border-color-base;
  // box-shadow: @card-shadow;
  border-style: @border-style-base;
  border-width: @border-width-base;
}

.code {
  code {
    padding: 2px 12px;
    flex: 1;
  }

  :global {
    @media (max-width: 995px) {
      .ant-typography {
        text-align: center;
      }
    }
  }
}

:global{
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.codeFlex {
  display: flex;
  justify-content: center;

  code {
    padding: 1px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    // antd style
    margin: 0 0.2em;
    font-size: 85%;
    color: #000000d9;
    background: rgba(150, 150, 150, 0.1);
    border: 1px solid rgba(100, 100, 100, 0.2);
    border-radius: 3px;
  }

  a {
    padding: 0 2px;
  }
}

.icons {
  font-size: 200%;
}

.withdrawButton {
  width: 100%;
}

.subwalletAlign {
  display: block;

  @media (min-width: 992px) {
    text-align: left;
  }
}

.subwalletAlignCenter {
  display: block;

  @media (min-width: 992px) {
    text-align: center;
  }
}

.send {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 996px) {
    justify-content: flex-start;
  }
}

.centerFlex {
  :global {
    .ant-space-item {
      display: flex;
    }
  }
}

.identicons {
  border-radius: 100px;
  margin-right: 0px;
}
@primary-color: #1DA57A;