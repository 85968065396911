@import '~antd/lib/style/themes/default.less';

.bottomMargin {
  margin-bottom: -1%;
}

.fillRow {
  height: 100%;
  background-color: transparent;

  :global {
    .ant-card-head {
      background-color: white;
    }

    .ant-card-body {
      padding: 0;
      box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
      border-radius: 0px 0px 15px 15px;
    }

    .ant-card-head {
      padding: 0 16px;
      border-radius:15px 15px 0px 0px;
      box-shadow: 0px 0px 24 5 rgba(208, 216, 243, 0.6);
    }
  }
}

.inlineTitle {
  display: inline;
  vertical-align: top;
}

.extra {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
  font-size: 200%;
}

.titleIcon {
  padding-right: 5%;
  font-size: 200%;
}

@primary-color: #1DA57A;