@import '~antd/es/style/themes/default.less';

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  background: white;

  .logo {
    position: relative;
    min-width: 165px;
    height: 100%;
    overflow: hidden;
  }

  .logo > img {
    display: inline-block;
    height: 32px;
    vertical-align: middle;
    margin-right: 12px;
  }

  .left {
    flex: 1;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .drawer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .right {
    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 0 5%;
  }
}

.container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;

  // sm
  @media (min-width: 576px) {
  }

  // md
  @media (min-width: 768px) {
  }

  // lg
  @media (min-width: 992px) {
  }

  // xl
  @media (min-width: 1200px) {
  }

  // xxl
  @media (min-width: 1600px) {
    max-width: 1440px;
  }
}

@primary-color: #1DA57A;