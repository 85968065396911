.stepsContent {
  min-height: 200px;
  margin-top: 16px;
  padding: 20px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.hidden {
  display: none;
}

.modalView {
  :global{
    .ant-modal-content {
      overflow: auto;
      border-radius: 20px
    }
  }
}
@primary-color: #1DA57A;